.header {
  display: flex;
  flex-direction: row;
  font-size: xx-large;
  font-weight: bolder;
  background-color: #ddd;
}

.header div {
  margin: 10px 20px;
}

.body {
  display: flex;
  flex-direction: row;
}

.menu {
  display: flex;
  flex: 1.5;
  flex-direction: column;
  padding: 5px;
}

.menu > span {
  display: flex;
  flex-direction: column;
}

.menu > span > span {
  margin-left: 20px;
}

img {
  max-width: 100%;
  max-height: 450px;
}

.desc {
  flex: 5;
}

.selected {
  background-color: antiquewhite;
}

.group {
  border: 1px solid greenyellow;
  cursor: pointer;
}
.group:hover {
  background-color: blanchedalmond;
}

.plant {
  border: 1px solid green;
  cursor: pointer;
}

.plant:hover {
  background-color: goldenrod;
}

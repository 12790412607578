.dragDrop {
    display: 'inline-block';
    position: 'relative';
}

.dragging {
    border: dashed grey 4px;
    background-color: rgba(31, 99, 29, 0.8);
    position: 'absolute';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.target {
    position: 'absolute';
    top: '50%';
    right: 0;
    left: 0;
    text-align: 'center';
    color: 'grey';
    font-size: 36;
}
.home {
  background-image: url('../../images/Rhododendron_20210418_102321.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 600px;
  text-align: center;
}

.home h1 {
  color: white;
  padding: 50px 0px;
  font-size: 2.5rem;
  text-shadow: 4px 4px #555;
}

.home .menu {
  margin: 20px;
  margin-bottom: 400px;
}

.home .menu span {
  color: white;
  border: 2px solid white;
  padding: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: rgba(100, 100, 100, .7);
  text-decoration: none;
}

.home .menu span:hover {
  background-color: rgba(200, 100, 100, .7);
}

.container {
  margin: auto;
  max-width: 1024px;
  padding: 10px;
}

.blog {
  width: 100%;
  text-align: left;
}

.blog h2 {
  font-size: xx-large;
}

.blog p {
  text-align: left;
  font-size: x-large;
}

.blog img {
  max-width: 100%;
  max-height: 450px;
}
.header {
  display: flex;
  flex-direction: row;
  font-size: xx-large;
  font-weight: bolder;
  background-color: #ddd;
}

.header div {
  margin: 10px 20px;
}

.panels {
  display: flex;
  margin: 10px;
  align-items: stretch;
  height: 100vh;
}

.panels > div {
  flex: 1;
}

.panels > div > div {
  margin: 10px;
}

.panels input,
.panels textarea {
  width: 95%;
}

.panels textarea {
  padding: 10px;
  height: 40vh;
}

.header {
  display: flex;
  flex-direction: row;
  font-size: xx-large;
  font-weight: bolder;
  background-color: #ddd;
}

.header div {
  margin: 10px 20px;
}

.dropZone {
  margin: 10px 0;
  padding: 10px;
  min-height: 2rem;
  border: 1px solid blue;
  background-color: rgb(220, 238, 245);
}

.inputs {
  display: flex;
}

.inputs input {
  flex: 1;
}

.inputs input:last-child {
  flex: 4;
}

.assign {
  display: flex;
  flex-direction: row;
}

.photos {
  flex: 1;
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.photos > div {
  border: 1px solid rgb(241, 100, 34);
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
}

.photos > div > img {
  max-height: 50px;
}

.plants {
  flex: 1;
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.plants > div {
  border: 1px solid rgb(25, 116, 37);
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
}
